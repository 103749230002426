<template>
	<div class="animated fadeIn">
		<div class="table-responsive">
			<table class="table table-hover table-striped">
				<thead>
					<tr>
						<th class="border-top-0">
							#
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer">-->
							<!--@click="sortByField('id')">-->
							{{ translate('id_word') }}
							<!--<sort field="id" />-->
						</th>
						<th
							v-if="$user.details().type === superAdmin"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('user_id')">-->
							{{ translate('user_id') }}
							<!--<sort field="user_id" />-->
						</th>
						<th
							v-if="$user.details().type === superAdmin"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('username')">-->
							{{ translate('username') }}
							<!--<sort field="username" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('full_name')">-->
							{{ translate('full_name') }}
							<!--<sort field="full_name" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('email')">-->
							{{ translate('email') }}
							<!--<sort field="email" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('total_amount')">-->
							{{ translate('total') }}
							<!--<sort field="total_amount" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('status')">-->
							{{ translate('status') }}
							<!--<sort field="status" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('date')">-->
							{{ translate('date_time') }}
							<!--<sort field="date" />-->
						</th>
						<th class="border-top-0">
							{{ translate('options') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td class="align-middle">
							{{ item.id }}
						</td>
						<td
							v-if="$user.details().type === superAdmin"
							class="align-middle">
							{{ item.attributes.user_id }}
						</td>
						<td
							v-if="$user.details().type === superAdmin"
							class="align-middle">
							{{ item.attributes.username }}
						</td>
						<td class="align-middle">
							{{ item.attributes.full_name }}
						</td>
						<td class="align-middle">
							{{ item.attributes.email }}
						</td>
						<td class="align-middle">
							{{ item.attributes.total_amount }}
						</td>
						<td class="align-middle">
							{{ translate(item.attributes.status.toLowerCase()) }}
						</td>
						<td class="align-middle">
							{{ item.attributes.date.date ? $moment(item.attributes.date.date).format(dateFormat) : $moment(item.attributes.date).format(dateFormat) }}
						</td>
						<td>
							<b-button
								v-if="$can('orders', 'invoice') && item.attributes.invoice"
								:disabled="loading"
								variant="primary"
								class="btn mx-1"
								@click="download(item.id)">
								<i
									class="fa fa-download"
									aria-hidden="true" />
							</b-button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>
import { PAGINATION } from '@/settings/RequestReply';
import {
	Report, Grids, PaymentMethods, Countries,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import { YMDHMS_FORMAT } from '@/settings/Dates';
import Sales from '@/util/Report';
import { superAdmin } from '@/settings/Roles';

export default {
	name: 'SalesAll',
	messages: [Report, Grids, PaymentMethods, Countries],
	mixins: [FiltersParams],
	data() {
		return {
			salesAll: new Sales(),
			dateFormat: YMDHMS_FORMAT,
			superAdmin,
		};
	},
	computed: {
		loading() {
			return !!this.salesAll.data.loading;
		},
		errors() {
			return this.salesAll.data.errors;
		},
		pagination() {
			return this.salesAll.data.pagination;
		},
		data() {
			try {
				const { data } = this.salesAll.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
	},
	mounted() {
		this.salesAll.getAllSales();
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			this.salesAll.getAllSales(options);
		},
	},
};
</script>
